// material
import { Chip, useTheme } from "@material-ui/core";
// utils
// motor JS

import { useSelections, useButton } from "@motor-js/engine";

export default function QlikSelections() {
  const { selections } = useSelections();
  const { selectValues } = useButton();
  const theme = useTheme();

  return selections === null
    ? null
    : selections.map((item, index) =>
        ["Last Year", "Last Last Year", "Budget"].includes(
          item.qSelected
        ) ? null : (
          <Chip
            key={index}
            label={item.qSelected}
            onDelete={
              ![
                "Value",
                "Volume",
                "Last Year",
                "Last Last Year",
                "Budget",
              ].includes(item.qSelected)
                ? () =>
                    selectValues(
                      [item.qSelected],
                      item.qField,
                      item.qSelectedCount > 1 ? false : true
                    )
                : null
            }
            onClick={() =>
              selectValues(
                [item.qSelected],
                item.qField,
                item.qSelectedCount > 1 ? false : true
              )
            }
            style={{
              backgroundColor:
                item.qField === "AsOfWeek"
                  ? theme.palette.primary.lighter
                  : null,
            }}
          />
        )
      );
}
