// routes for the app are here
// when making an app consider what the default root should be
// renders pages in the views folder

import React, { Suspense, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import PropTypes from "prop-types";
// material
// material
import { experimentalStyled as styled } from "@material-ui/core/styles";

//
import LoadingScreen from "../components/LoadingScreen";

import DashboardNavbar from "../layouts/dashboard/DashboardNavbar";
import DashboardSidebar from "../layouts/dashboard/DashboardSidebar";
import { EngineContext } from "@motor-js/engine";

// ---- Import Pages ------------------------------------------------------------------

const PageOne = React.lazy(() => import("../views/PageOne")); // Lazy-loaded

// ------------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

function DashboardLayout({ children }) {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
}

export default function App() {
  const { engine } = useContext(EngineContext);

  return (
    <>
      {engine === undefined ? (
        <LoadingScreen />
      ) : (
        <Router>
          <Suspense fallback={<LoadingScreen />}>
            <div>
              <DashboardLayout>
                <Switch>
                  <Route path="/explore">
                    <PageOne />
                  </Route>
                  <Route exact path="/">
                    <Redirect to="/explore" />
                  </Route>
                </Switch>
              </DashboardLayout>
            </div>
          </Suspense>
        </Router>
      )}
    </>
  );
}
