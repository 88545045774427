import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// material
import { alpha, experimentalStyled as styled } from "@material-ui/core/styles";
import {
  Box,
  List,
  Drawer,
  Hidden,
  ListSubheader,
  Typography,
  Button,
} from "@material-ui/core";
// components
import Scrollbar from "../../components/Scrollbar";
import PoweredByAutone from "./PoweredByAutone";

//
// import MenuLinks from './SidebarConfig';
import SidebarPage from "./SidebarPage";

// import qlik component

import QlikDropDown from "../../components/qlik/QlikDropDown";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const DocStyle = styled("div")(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor:
    theme.palette.mode === "light"
      ? alpha(theme.palette.primary.main, 0.08)
      : theme.palette.primary.lighter,
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 2.5, py: 3, pl: 2 }}>
        <Box
          component="img"
          alt="logo"
          src="/static/brand/logo_large.svg"
          height={50}
          sx={{ ml: 3, mt: 1 }}
        />
      </Box>

      <List
        disablePadding
        key="Pages"
        subheader={
          <>
            <ListSubheader
              disableSticky
              disableGutters
              sx={{
                mt: 3,
                mb: 2,
                pl: 5,
                color: "text.primary",
                typography: "overline",
              }}
            >
              Apps
            </ListSubheader>

            <SidebarPage
              title="Explore"
              href="explore"
              icon="ic_page"
              key="explore"
            />
          </>
        }
      />

      <List
        disablePadding
        key="Parameters"
        subheader={
          <>
            <ListSubheader
              disableSticky
              disableGutters
              sx={{
                mt: 3,
                mb: 2,
                pl: 5,
                color: "text.primary",
                typography: "overline",
              }}
            >
              Parameters
            </ListSubheader>
            <QlikDropDown title="metric" icon="ic_map" singleSelect={true} />
          </>
        }
      />

      <Box
        sx={{
          mb: 5,
        }}
      >
        <List
          disablePadding
          key="Pages3"
          subheader={
            <>
              <ListSubheader
                disableSticky
                disableGutters
                sx={{
                  mt: 3,
                  mb: 2,
                  pl: 5,
                  color: "text.primary",
                  typography: "overline",
                }}
              >
                Filters
              </ListSubheader>

              <QlikDropDown title="Region" icon="ic_map" singleSelect={false} />
              <QlikDropDown
                title="Location"
                icon="ic_map"
                singleSelect={false}
              />
              <QlikDropDown
                title="LocationType"
                icon="ic_map"
                singleSelect={false}
              />
              <QlikDropDown title="gender" icon="ic_map" singleSelect={false} />
              <QlikDropDown
                title="Function"
                icon="ic_map"
                singleSelect={false}
              />
              <QlikDropDown
                title="MarkDown"
                icon="ic_map"
                singleSelect={false}
              />
              <QlikDropDown
                title="seasonality"
                icon="ic_map"
                singleSelect={false}
              />
              <QlikDropDown
                title="capsule"
                icon="ic_map"
                singleSelect={false}
              />
            </>
          }
        />
      </Box>

      <Box sx={{ px: 2.5, pb: 3, mt: 10, ml: 2, mr: 2 }}>
        <DocStyle>
          <Typography
            gutterBottom
            variant="subtitle1"
            sx={{ color: "grey.800" }}
          >
            Hello!
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: "grey.600" }}>
            Need help?
            <br /> Please contact us
          </Typography>
          <Button fullWidth variant="contained" href="mailto:adil@autone.io">
            Support
          </Button>
        </DocStyle>
      </Box>

      <Box
        sx={{
          mb: 10,
          mt: 2,
        }}
        style={{ textAlign: "center" }}
      >
        <PoweredByAutone />
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: "background.default" },
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
